import Box from '@rexlabs/box';
import { SubHeading } from 'components/text/sub-heading';
import { StatusCircle } from 'view/components/status-circle';
import { Body } from 'components/text/body';
import dayjs from 'dayjs';
import { PortalStatsFilterPopout } from 'features/portal-stats/components/portal-stats-filter';
import PaddingBox from 'view/components/padding-box';
import ErrorList from 'features/apps/xero/components/error-list';
import React from 'react';
import {
  Filter,
  MarketingCampaign,
  SuggestedFilter
} from 'features/portal-stats/data/performance';

const SUMMARY_STATS_COPY =
  'Showing data from all campaigns linked to this listing. If multiple campaigns are associated, the data reflects the combined total.';

interface PerformanceHeaderProps {
  selectedPortal: MarketingCampaign;
  setFilter: (filterId: string) => Promise<void>;
  filtersConfig: Filter[];
  selectedFilter: SuggestedFilter;
}
const PerformanceHeader = ({
  selectedPortal,
  setFilter,
  filtersConfig,
  selectedFilter
}: PerformanceHeaderProps) => {
  const hasSuggestedFilters = selectedPortal?.suggested_filters?.length > 0;
  return (
    <PaddingBox white padding={'14px 25px'}>
      <Box justifyContent='space-between' mb={14}>
        <>
          <div>
            <SubHeading semibold>Listing Performance</SubHeading>
            <Box alignItems='center'>
              <StatusCircle
                green={selectedPortal.sync_errors.length === 0}
                red={selectedPortal.sync_errors.length > 0}
              />
              <Body dark normal>
                Last updated:{' '}
                {dayjs(selectedPortal.last_updated).format('DD MMMM YYYY')}
              </Body>
            </Box>
          </div>
          {hasSuggestedFilters && (
            <PortalStatsFilterPopout
              selectedFilter={selectedFilter}
              filters={filtersConfig}
              onFilter={setFilter}
            />
          )}
        </>
      </Box>

      {!hasSuggestedFilters && (
        <Body small dark style={{ fontWeight: 400 }}>
          {SUMMARY_STATS_COPY}
        </Body>
      )}
    </PaddingBox>
  );
};

export default PerformanceHeader;
