import React, { useMemo, useState } from 'react';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { Form, FormField, ReactForms } from 'view/components/form';
import PaddingBox from 'view/components/padding-box';
import { Popout } from 'view/components/popout';
import { ButtonBar } from 'view/components/button-bar';
import { DefaultButton, TextButton } from 'view/components/button';
import { Column, Grid } from 'shared/components/grid';
import {
  Filter,
  SuggestedFilter
} from 'features/portal-stats/data/performance';
import Icon, { ICONS } from 'shared/components/icon';
import { Body } from 'components/text/body';

const defaultStyles = StyleSheet({
  form: {
    '& input': {
      '&:hover': {
        border: '0px'
      },
      '&:focus': {
        border: '0px'
      }
    }
  }
});

interface PortalStatsFilterPopout {
  onFilter: (filterId: string) => Promise<void>;
  filters: Filter[];
  selectedFilter?: SuggestedFilter;
}

function FilterContent({
  filters,
  onFilter,
  selectedFilter,
  close
}: PortalStatsFilterPopout & { close: () => void }) {
  const s = useStyles(defaultStyles);
  type Values = Record<(typeof filters)[number]['field'], any>;
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = useMemo(() => {
    return { suggested_filters: selectedFilter?.id };
  }, [selectedFilter]);

  const handleSubmit = async ({ suggested_filters }: Values) => {
    if (suggested_filters) {
      setIsLoading(true);
      await onFilter(suggested_filters);
      setIsLoading(false);
    }
    close();
  };

  return (
    <Box w={'320px'}>
      <ReactForms<Values, unknown>
        initialValues={initialValues}
        handleSubmit={handleSubmit}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <PaddingBox {...s('form')}>
              <Grid columns={1} mt={-15} pr={4}>
                {filters.map(
                  ({ field, label, Input, inputProps = {} }, index) => (
                    <Column key={field} width={1}>
                      <FormField
                        name={field}
                        label={label}
                        Input={Input}
                        inputProps={inputProps}
                      />
                    </Column>
                  )
                )}
              </Grid>
              <Box display='flex' justifyContent='space-between' marginTop={20}>
                <ButtonBar isLoading={isLoading} hasPadding={false}>
                  <TextButton blue onClick={close}>
                    Cancel
                  </TextButton>
                  <DefaultButton blue onClick={submitForm}>
                    Apply Filter
                  </DefaultButton>
                </ButtonBar>
              </Box>
            </PaddingBox>
          </Form>
        )}
      </ReactForms>
    </Box>
  );
}
export function PortalStatsFilterPopout({
  onFilter,
  filters,
  selectedFilter
}: PortalStatsFilterPopout) {
  return (
    <Popout
      Content={({ close }) => (
        <FilterContent
          close={close}
          filters={filters}
          onFilter={onFilter}
          selectedFilter={selectedFilter}
        />
      )}
      placement={'bottom-start'}
    >
      <Box flexDirection='row' alignItems='center' spacing={5}>
        <Icon type={ICONS.CALENDAR_12} width={'12px'} height={'12px'} />
        <Body semibold dark>
          {selectedFilter?.title ?? ''}
        </Body>
        <Icon
          type={ICONS.ARROW_DOWN}
          width={'32px'}
          height={'32px'}
          style={{ transform: 'rotate(-90deg)', marginLeft: -5 }}
        />
      </Box>
    </Popout>
  );
}
