import { Body } from 'components/text/body';
import React, { useCallback, useState } from 'react';
import Icon, { ICONS } from 'shared/components/icon';
import PaddingBox from 'src/view/components/padding-box';
import { Popout } from 'src/view/components/popout';
import Box from '@rexlabs/box';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { COLORS } from 'src/theme';
import { ListingPerformance, MarketingCampaign } from '../data/performance';
import { SubHeading } from 'components/text/sub-heading';
import ErrorList from 'features/apps/xero/components/error-list';

const styles = StyleSheet({
  externalIcon: {
    marginLeft: 2,
    '& svg': {
      width: 8.33,
      height: 8.33
    }
  },
  button: {
    backgroundColor: 'transparent',
    outline: 'none',
    border: 'none',
    padding: '10px 15px',
    textAlign: 'left',
    width: '100%',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: COLORS.STATES.BG_BLUE_HOVER
    }
  },
  copied: {
    padding: '12px 14px',
    backgroundColor: '#000',
    boxShadow: '1px 1px 5px #AAA',
    '& svg path': {
      stroke: '#fff'
    }
  }
});

interface PortalSelectProps {
  listingPerformance: ListingPerformance;
  selectedPortal: MarketingCampaign;
  setSelectedPortal: (portal: MarketingCampaign) => void;
}

const PortalSelect = ({
  selectedPortal,
  setSelectedPortal,
  listingPerformance
}: PortalSelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const s = useStyles(styles);

  const Content = useCallback(
    ({ close }) => {
      return (
        <Box width={200} p={5}>
          {listingPerformance.campaigns.map((portal) => (
            <button
              type='button'
              key={portal.id}
              onClick={() => {
                setSelectedPortal(portal);
                close();
              }}
              {...s('button')}
            >
              <Body dark normal>
                {portal.source_title}
              </Body>
            </button>
          ))}
        </Box>
      );
    },
    [setSelectedPortal, s, listingPerformance.campaigns]
  );

  const Copied = useCallback(() => {
    return (
      <Box alignItems='center' {...s('copied')}>
        <Icon
          type={ICONS.CHECK}
          width={16}
          height={16}
          style={{ display: 'flex', marginRight: 10 }}
        />
        <Body white normal regular>
          Link copied
        </Body>
      </Box>
    );
  }, [s]);

  return (
    <Box mb={20}>
      <PaddingBox
        justifyContent='space-between'
        alignItems='center'
        white
        padding={'14px 25px'}
      >
        <Popout Content={Content}>
          <Box flexDirection='row' alignItems='center' spacing={8}>
            <Icon
              type={ICONS.PORTAL_STATS[selectedPortal.id.toUpperCase()]}
              width={'32px'}
              height={'32px'}
              hasControlledColor={false}
            />
            <SubHeading semibold>{selectedPortal.source_title}</SubHeading>
            <Icon
              type={ICONS.ARROW_DOWN}
              width={'32px'}
              height={'32px'}
              style={{ transform: 'rotate(-90deg)', marginLeft: -5 }}
            />
          </Box>
        </Popout>
      </PaddingBox>
      {selectedPortal.sync_errors.length > 0 && (
        <PaddingBox white p={'0px'} m={0}>
          <ErrorList
            marginBottom={0}
            errors={selectedPortal.sync_errors.map(
              ({ error }) => error.error_message
            )}
          />
        </PaddingBox>
      )}
    </Box>
  );
};

export default PortalSelect;
