import Box from '@rexlabs/box';
import { Body } from 'components/text/body';
import Icon, { ICONS } from 'shared/components/icon';
import { COLORS } from 'src/theme';
import { TextButton } from 'src/view/components/button';

interface MapStateProps {
  mapError: string;
  inQueue: boolean;
  onEditCoordinates: () => void;
  reloadPropertyCoordinates: () => void;
}

const MapState = ({
  mapError,
  inQueue,
  onEditCoordinates,
  reloadPropertyCoordinates
}: MapStateProps) => {
  if (!mapError && !inQueue) return null;
  if (inQueue) {
    return (
      <Box
        flexDirection='row'
        p={10}
        style={{ backgroundColor: COLORS.GREY_FEINT }}
      >
        <Icon
          type={ICONS.INFO_SMALL}
          style={{
            display: 'flex',
            color: COLORS.PRIMARY.GREY_DARK,
            marginTop: 2
          }}
        />
        <Box ml={10}>
          <Body small dark semibold style={{ marginBottom: 4 }}>
            We&apos;re fetching the location details. It might take a moment for
            the map to update while we retrieve the coordinates.
          </Body>
          <TextButton
            height='unset'
            padding='0px'
            onClick={reloadPropertyCoordinates}
          >
            <Body normal small dark semibold>
              Try again
            </Body>
          </TextButton>
        </Box>
      </Box>
    );
  }
  return (
    <Box
      flexDirection='row'
      p={10}
      style={{ backgroundColor: COLORS.CORAL_PINK_FAINT }}
    >
      <Icon
        type={ICONS.ROUND_CROSS_BIG}
        width={12}
        height={12}
        hasControlledColor={false}
        style={{ display: 'flex', marginTop: 2 }}
      />
      <Box ml={10}>
        <Body small dark semibold style={{ marginBottom: 4 }}>
          {mapError}
        </Body>
        <TextButton height='unset' padding='0px' onClick={onEditCoordinates}>
          <Body normal small dark semibold>
            Edit coordinates
          </Body>
        </TextButton>
      </Box>
    </Box>
  );
};

export default MapState;
