import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';
import { identity } from 'lodash';
import { AxiosResponse } from 'axios';
import { ComponentType } from 'react';

type Stats = {
  id: string;
  value: number | string;
  label: string;
};

export type EngagementStat = {
  clicks: number;
  impressions: number;
  time: string;
};

export interface SyncError {
  error: {
    error_code: number;
    error_message: string;
  };
}

export type SuggestedFilter = {
  id: string;
  label: string;
  title: string;
  from?: string;
  to?: string;
};

export type Filter = {
  field: string;
  label: string;
  Input: ComponentType<any>;
  inputProps?: Record<string, any>;
};

export type MarketingCampaign = {
  id: string;
  source_title: string;
  last_updated: string;
  primary_stats: Stats[];
  secondary_stats: {
    title: string;
    data: Stats[];
  }[];
  engagement_stats?: EngagementStat[];
  suggested_filters: SuggestedFilter[];
  sync_errors: SyncError[];
};

export type ListingPerformance = {
  listing_id: string;
  campaigns: MarketingCampaign[];
};

export interface GetListingStatsPayload {
  listing_id: string;
  most_recent?: boolean;
  date_from?: string;
  date_to?: string;
  portal_format?: string;
}

export interface PortalStatsModel {
  getListingStats: (
    payload: GetListingStatsPayload
  ) => Promise<AxiosResponse<{ result: ListingPerformance }>>;
}

const TYPE = 'portalStats';

const actionCreators = {
  getListingStats: {
    request: (payload: GetListingStatsPayload) => {
      return api.post('PortalStats::getListingStats', payload);
    },
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  }
};

const portalStatsModel = new Generator(TYPE).createEntityModel({
  actionCreators
});

export default portalStatsModel;
