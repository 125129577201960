import { Body } from 'components/text/body';
import React from 'react';
import PaddingBox from 'src/view/components/padding-box';
import { MarketingCampaign } from '../data/performance';
import { Column, Grid } from 'shared/components/grid';
import { StyleSheet, useStyles } from '@rexlabs/styling';

// Body uses inline styling that is why important is needed
const styles = StyleSheet({
  count: {
    fontSize: '27px !important'
  },
  label: {
    lineHeight: '17px !important'
  }
});

interface PrimaryStatsProps {
  selectedPortal: MarketingCampaign;
}

const PrimaryStats = ({ selectedPortal }: PrimaryStatsProps) => {
  const s = useStyles(styles);

  return (
    <PaddingBox white padding={'0px 25px 14px 25px'}>
      <Grid columns={selectedPortal.primary_stats.length} m={'0 -5px'}>
        {selectedPortal.primary_stats.map((stat) => (
          <Column width={1} p={'0 5px'} key={stat.id}>
            <PaddingBox
              light
              padding={'15px 15px 18px'}
              height={99}
              flexDirection='column'
              justifyContent='space-between'
              style={{ borderRadius: 4 }}
            >
              <Body semibold dark {...s('label')}>
                {stat.label}
              </Body>
              <Body semibold dark {...s('count')}>
                {stat.value}
              </Body>
            </PaddingBox>
          </Column>
        ))}
      </Grid>
    </PaddingBox>
  );
};

export default PrimaryStats;
