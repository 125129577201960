import { ColumnConfig } from 'components/record-list-screen/types';
import { ListingDocument } from 'features/listings/types/common';
import { EllipsisCell } from 'components/record-list-screen/cells/ellipsis-cell';
import dayjs from 'dayjs';
import { DocumentsTableAccessCell } from 'features/listings/components/documents-table/documents-table-access-cell';
import { formatFileSize } from 'features/listings/components/documents-table/helpers';

const descriptionColumn: ColumnConfig<ListingDocument> = {
  id: 'description',
  label: 'filename',
  width: 185,
  forced: true,
  Cell: EllipsisCell
};

const typeColumn: ColumnConfig<ListingDocument> = {
  id: 'type',
  label: 'type',
  forced: true,
  sortable: 'type.text',
  width: 119,
  selector: (row) => row.type.text,
  Cell: EllipsisCell
};

const accessColumn: ColumnConfig<ListingDocument> = {
  id: 'access',
  label: 'access',
  sortable: 'privacy.text',
  forced: true,
  selector: (row) => row.privacy.text,
  Cell: DocumentsTableAccessCell
};

const dateUploadedColumn: ColumnConfig<ListingDocument> = {
  id: 'date_uploaded',
  label: 'date uploaded',
  forced: true,
  sortable: 'system_ctime',
  width: 110,
  selector: (row) => dayjs.unix(row.system_ctime).format('D MMM YYYY')
};

const fileSizeColumn: ColumnConfig<ListingDocument> = {
  id: 'filesize',
  label: 'file size',
  forced: true,
  sortable: 'system_size_mb',
  selector: (row) => formatFileSize(`${row.system_size_mb * 1000000}`)
};

export {
  descriptionColumn,
  accessColumn,
  dateUploadedColumn,
  typeColumn,
  fileSizeColumn
};
