import React, { useState, useEffect, useCallback } from 'react';
import PortalSelect from './components/portal-select';
import Box from '@rexlabs/box';
import { COLORS } from 'src/theme';
import PrimaryStats from './components/primary-stats';
import portalStatsModel, {
  EngagementStat,
  GetListingStatsPayload,
  ListingPerformance,
  MarketingCampaign,
  PortalStatsModel,
  Filter
} from './data/performance';
import SecondaryStats from './components/secondary-stats';
import EngagementStats from './components/engagement-stats';
import { useModelActions } from '@rexlabs/model-generator';
import { useErrorDialog } from 'hooks/use-error-dialog';
import PaddingBox from 'src/view/components/padding-box';
import Spinner from 'shared/components/spinner';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { RadioGroupInput } from 'view/components/input/radio-buttons';
import PerformanceHeader from 'features/portal-stats/components/performance-header';

const styles = StyleSheet({
  empty: {
    fontSize: '17px',
    fontWeight: 600,
    fontStyle: 'italic',
    color: COLORS.PRIMARY.INFORMATIVE,
    textAlign: 'center'
  }
});

interface PortalStatsProps {
  listing_id: string;
}

const createListingStatsPayload = ({
  listingId,
  selectedFilterId,
  selectedPortal
}) => {
  const payload: GetListingStatsPayload = {
    listing_id: listingId,
    most_recent: !selectedFilterId
  };

  if (selectedPortal) {
    payload.portal_format = selectedPortal.id;
    const criteria = selectedPortal.suggested_filters?.find(
      (filter) => filter.id === selectedFilterId
    );

    if (criteria) {
      payload.date_from = criteria.from;
      payload.date_to = criteria.to;
    }
  }

  return payload;
};

const PortalStats = ({ listing_id }: PortalStatsProps) => {
  const s = useStyles(styles);
  const errorDialog = useErrorDialog();

  const { getListingStats } = useModelActions(
    portalStatsModel
  ) as unknown as PortalStatsModel;

  const [listingPerformance, setListingPerformance] =
    useState<ListingPerformance | null>(null);

  const [selectedPortal, setSelectedPortal] =
    useState<MarketingCampaign | null>(null);

  const [selectedPortalFilters, setSelectedPortalFilters] = useState<{
    [key: string]: string;
  }>({});

  const [isLoading, setIsLoading] = useState(true);

  const getListingStatsAsync = useCallback(
    async ({ listingId, selectedFilterId, selectedPortal }) => {
      const payload = createListingStatsPayload({
        listingId,
        selectedFilterId,
        selectedPortal
      });

      try {
        const res = await getListingStats(payload);
        setListingPerformance((prevState) => {
          if (!prevState || !payload.portal_format) return res.data.result;
          const updatedCampaigns = prevState.campaigns.map((campaign) => {
            if (campaign.id === selectedPortal.id) {
              const updatedCampaign = res.data.result.campaigns.find(
                (newCampaign) => newCampaign.id === campaign.id
              );
              return updatedCampaign
                ? { ...campaign, ...updatedCampaign }
                : campaign;
            }
            return campaign;
          });

          return {
            ...prevState,
            campaigns: updatedCampaigns
          };
        });

        if (!selectedPortal) {
          setSelectedPortal(res.data.result.campaigns[0] ?? null);
        } else {
          const newPortal = res.data.result.campaigns.find(
            (c) => c.id === selectedPortal.id
          );
          setSelectedPortal(newPortal ?? null);
        }
      } catch (error) {
        errorDialog.open(error as Error);
      }
    },
    [errorDialog, getListingStats]
  );

  useEffect(() => {
    getListingStatsAsync({
      listingId: listing_id,
      selectedFilterId: null,
      selectedPortal: null
    }).then(() => setIsLoading(false));
  }, [listing_id]);

  const setFilter = async (filterId: string) => {
    if (selectedPortal) {
      await getListingStatsAsync({
        listingId: listing_id,
        selectedFilterId: filterId,
        selectedPortal: selectedPortal
      });
      setSelectedPortalFilters((prev) => ({
        ...prev,
        [selectedPortal.id]: filterId
      }));
    }
  };

  const suggestedFilters = selectedPortal?.suggested_filters || [];

  const selectedFilter = selectedPortal?.id
    ? suggestedFilters.find(
        (filter) => filter.id === selectedPortalFilters[selectedPortal.id]
      ) ?? suggestedFilters[0]
    : suggestedFilters[0];

  const filtersConfig: Filter[] = [
    {
      field: 'suggested_filters',
      label: '',
      Input: RadioGroupInput,
      inputProps: {
        options: suggestedFilters.map((filter) => ({
          label: filter.label,
          value: filter.id,
          criteria: {
            from: filter.from,
            to: filter.to
          }
        }))
      }
    }
  ];

  if (isLoading) {
    return (
      <PaddingBox p={'50px 150px'}>
        <Spinner small centered dark classic />
      </PaddingBox>
    );
  }

  if (!listingPerformance || !selectedPortal)
    return (
      <PaddingBox
        p={'50px 150px'}
        style={{ borderTop: `2px solid ${COLORS.BORDERS.GREY}` }}
      >
        <p {...s('empty')}>
          Currently no performance data available as this listing has no
          recorded activity.
        </p>
      </PaddingBox>
    );

  return (
    <Box pt={20} style={{ borderTop: `2px solid ${COLORS.BORDERS.GREY}` }}>
      <>
        <PortalSelect
          listingPerformance={listingPerformance}
          selectedPortal={selectedPortal}
          setSelectedPortal={setSelectedPortal}
        />

        {!selectedPortal.last_updated ? (
          <PaddingBox p={'50px 150px'}>
            <p {...s('empty')}>
              {`Currently no performance data available for ${selectedPortal.source_title}.`}
            </p>
          </PaddingBox>
        ) : (
          <>
            <PerformanceHeader
              selectedPortal={selectedPortal}
              setFilter={setFilter}
              filtersConfig={filtersConfig}
              selectedFilter={selectedFilter}
            />

            <PrimaryStats selectedPortal={selectedPortal} />

            {!!selectedPortal?.engagement_stats?.length && (
              <EngagementStats
                engagementStats={
                  selectedPortal.engagement_stats as EngagementStat[]
                }
              />
            )}
            <SecondaryStats selectedPortal={selectedPortal} />
          </>
        )}
      </>
    </Box>
  );
};

export default PortalStats;
